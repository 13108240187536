<!--
File: Report_3_YearwiseRoadLength.vue
Description: produces the pivot with the yearwise length of the roads for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='onDeuChange' :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <YearsDropdown class='region-select-dropdown' :label="$t('label.from_year')" v-model="startYear"
            :initial_value="startYear" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <YearsDropdown class='region-select-dropdown' :label="$t('label.to_year')" v-model="endYear"
            :initial_value="endYear" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled">{{ $t('buttons.print') }}</md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />

      <div class='md-layout-item md-size-50 md-small-size-100 md-medium-size-50'>
        <canvas v-if="!emptyData" ref="myChart" id="YearwiseLengthChart" height="100px"></canvas>
      </div>

      <md-table class="paginated-table table-striped table-hover" :value="tableData"  md-fixed-header height="50%">
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 250px;" :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')">
            {{ $t('road_network.dep') + '-' + item.deu_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('budget.year')">
            {{ item.year }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length_km_road_class_em')" md-numeric>
            {{ item.length_km_road_class_em | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length_km_road_class_m')" md-numeric>
            {{ item.length_km_road_class_m | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length_km_road_class_zh')" md-numeric>
            {{ item.length_km_road_class_zh | numFormat(3) }}
          </md-table-cell>
          <!--md-table-cell :md-label="$t('road_network.road_class_none')" md-numeric>
            {{ item.length_km_road_class_none | numFormat }}
          </md-table-cell-->
          <md-table-cell :md-label="$t('road_network.total_km')" md-numeric>
            {{ item.total_length_km | numFormat(3) }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <!--div class="md-layout">
        <div>{{ $t('total_regions: ') }}: {{ total_regions }}&nbsp; </div>
          <div>{{ $t('total_deu: ') }}: {{ total_deu }}&nbsp; </div>>
      </div-->
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  import YearsDropdown from '../Dropdowns/YearsDropdown.vue'
  import Chart from 'chart.js';
  import html2canvas from 'html2canvas';
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/customSortMixin'

  export default {
    name: 'roads-yearwise-length',
    mixins: [customSortMixin],
    data() {
      return {
        selectedRegion: null,
        startYear: new Date().getFullYear(),
        endYear: new Date().getFullYear(),
        selected_road_class: '',
        selectedDeu: 0,
        btnDisabled: true,

        showSpinner: false,
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        regionDescr: null,
        deuDescr: null,
        chart: null,
      }
    },

    components: {
      RegionsDropdown,
      DepsDropdown,
      YearsDropdown,
    },

    mounted() {
      this.showSpinner = true
      this.$store.state.Reports.report_3_data = []
      this.reloadData()
    },

    beforeDestroy() {
      if (this.chart) this.chart.destroy();
    },

    methods: {
      reloadData() {
        if (!this.startYear || !this.endYear) { return }

        this.showSpinner = true
        this.btnDisabled = true
        const reportParams = {
          start_year: this.startYear,
          end_year: this.endYear,
          region_id: this.selectedRegion,
          road_class: this.selected_road_class,
          deu_id: this.selectedDeu
        }
        this.$store.dispatch('REPORT_3_YEARWISE_LENGTH', reportParams).then(() => {
          this.reloadChart()
          this.btnDisabled = false
          this.showSpinner = false
        })
      },
      async reloadChart() {
        if (this.emptyData) return

        var years = { 0: {}, 1: {}, 2: {}, 3: {} }
        var years1 = []

        this.tableData.forEach((item) => {
          const ind1 = years1.findIndex((element) => element == item.year)
          if (ind1 < 0) { years1.push(item.year) }

          if (!years[0].hasOwnProperty(item.year)) {
            years[0][item.year] = item.length_km_road_class_m
          } else {
            years[0][item.year] += item.length_km_road_class_m
          }
          if (!years[1].hasOwnProperty(item.year)) {
            years[1][item.year] = item.length_km_road_class_em
          } else {
            years[1][item.year] += item.length_km_road_class_em
          }
          if (!years[2].hasOwnProperty(item.year)) {
            years[2][item.year] = item.length_km_road_class_zh
          } else {
            years[2][item.year] += item.length_km_road_class_zh
          }
          if (!years[3].hasOwnProperty(item.year)) {
            years[3][item.year] = item.length_km_road_class_none
          } else {
            years[3][item.year] += item.length_km_road_class_none
          }
        })

        var data_series = []
        for (var cl in years) {
          const dim2 = []
          for (var y in years[cl]) {
            dim2.push(years[cl][y])
          }
          data_series.push(dim2)
        }

        if (this.chart) this.chart.destroy() // Уничтожаем предыдущий график перед созданием нового
        const ctx = document.getElementById('YearwiseLengthChart').getContext('2d');
        this.chart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: years1,
            datasets: [{
              barThickness: 40,
              maxBarThickness: 50,
              label: this.$t('road_network.length_km_road_class_em'),
              data: data_series[0],
              backgroundColor: 'rgba(0, 255, 0, 0.2)',
              borderColor: 'rgba(0, 255, 0, 1)',
              borderWidth: 1
            }, {
              barThickness: 40,
              maxBarThickness: 50,
              label: this.$t('road_network.length_km_road_class_m'),
              data: data_series[1],
              backgroundColor: 'rgba(54, 162, 235, 0.2)',
              borderColor: 'rgba(54, 162, 235, 1)',
              borderWidth: 1
            }, {
              barThickness: 40,
              maxBarThickness: 50,
              label: this.$t('road_network.length_km_road_class_zh'),
              data: data_series[2],
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1
            }]
          },
          options: {
            scales: {
              xAxes: [{ stacked: true, ticks: { beginAtZero: true } }],
              yAxes: [{ stacked: true, ticks: { beginAtZero: true } }]
            },
          }
        });
      },
      print() {
        this.showSpinner = true
        let prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('label.report3_title')
        prtHtml += '&nbsp;' + this.startYear + ' - ' + this.endYear + '<br>'

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.regionDescr })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (this.selectedDeu) {
          titleFilters += ', &nbsp;' + this.$t('road_network.dep') + ': ' + this.deuDescr
        } else {
          tableHeaders += `<th>${this.$t('road_network.dep')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        const chartElement = this.$refs.myChart
        html2canvas(chartElement).then((canvas) => {
          const chartImage = canvas.toDataURL('image/png');
          prtHtml += `<img src="${chartImage}" style="width:100%;max-width:600px;">`;

          tableHeaders += `<th>${this.$t('budget.year')}</th>
            <th>${this.$t('road_network.length_km_road_class_em')}</th>
            <th>${this.$t('road_network.length_km_road_class_m')}</th>
            <th>${this.$t('road_network.length_km_road_class_zh')}</th>
            <th>${this.$t('road_network.total_km')}</th>
          </tr>`

          let tableRows = ''
          this.report.forEach(item => {
            tableRows += '<tr>'
            if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
            if (!this.selectedDeu) tableRows += `<td>${this.$t('road_network.dep') + '-' + item.deu_description}</td>`
            tableRows += `<td>${item.year}</td>
              <td class='numCell'>${numFormat(item.length_km_road_class_em, 3)}</td>
              <td class='numCell'>${numFormat(item.length_km_road_class_m, 3)}</td>
              <td class='numCell'>${numFormat(item.length_km_road_class_zh, 3)}</td>
              <td class='numCell'>${numFormat(item.total_length_km, 3)}</td>
            </tr>`
          })

          const PrintWin = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
          PrintWin.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <style>
              body { font-family: Arial, sans-serif; }
              table { width: 100%; border-collapse: collapse; margin: 20px 0; font-size: 11px; }
              th, td { border: 1px solid black; padding: 5px; overflow: visible; white-space: normal; word-wrap: break-word; }
              .numCell { text-align: right; padding-right: 5px; }
            </style>
          </head>
          <body>${prtHtml}
            <table>
              <thead>${tableHeaders}</thead>
              <tbody>${tableRows}</tbody>
            </table>
          </body>
        </html>`
          )

          PrintWin.document.close();
          this.showSpinner = false
          PrintWin.focus();
          PrintWin.print();
          PrintWin.close();
        })
      },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
        this.reloadData()
      },
      onDeuChange(deu_id, deuDescr) {
        this.deuDescr = deuDescr
        this.reloadData()
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_3_data, 'deu_description')
      },
      tableData() {
        let result = !this.selectedRegion ? this.report : this.report.filter((item) => {
          return item.region_id == this.selectedRegion
        })
        if (this.selectedDeu) {
          result = result.filter((item) => { return item.deu_id == this.selectedDeu })
        }
        return result
      },
      emptyData() {
        return this.tableData.length == 0
      }
    },

    watch: {
      startYear(value) {
        this.reloadData()
      },
      endYear(value) {
        this.reloadData()
      }
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}</style>